<template>
  <static-fullscreen-card>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
      <a-btn-add :icon="'far fa-plus-square'" :title="'Создать'" v-if="getAccess('invite.create')" @click="createNew()" />
    </template>
    <portal to="v-main">
      <edit-dialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" />
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="getModelList(m)"
      :useQuery="false"
      :defaults="defaults"
      :selectedRows="selectedRows"
      @update-data="updateData($event)"
      @click="
        counter += 1;
        onDetectClick($event);
      "
    >
      <template v-slot:footer>
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-if="$refs.table">Показано: {{ $refs.table.pager.count }}</div>
          </div>
        </div>
      </template>
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect, popupMenu } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect, popupMenu],
  components: {
    editDialog: () => import("./dialogs/inviteDialog"),
  },
  data() {
    return {
      idEdit: 0,
      idItemShow: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.invite,
      url: "/mechti/invite_registration",
    };
  },
  created() {
    this.$root.title = this.m.title;
  },
  computed: {
    defaults() {
      return {
        sort: { key: "id", order: "DESC" },
        filters: {},
        paramName: "invite",
        where: this.getAccess("invite.viewAll") ? {} : { createdby_id: this.$root.profile.id },
      };
    },
    configHead() {
      return this.m.config.default || {};
    },
    permit() {
      return this.getAccess("menu.settings.invaite");
    },
    infoSnack() {
      if (this.keysModified) {
      }
      let res = this.keys?.ctrl;
      this.infoData = null;
      this.infoModel = null;
      if (res) this.showOrderDetail();
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    onSingleClick(d) {
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    onClickRow(d) {
      return this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    updateData(v) {
      // this.getTotal(v.filters);
    },
  },
};
</script>
